$(document).ready(function(){

  $('#hn_review .owl-carousel').owlCarousel({
    loop:true,
    margin:0,
    dots:true,
    autoplay:true,
    autoplayTimeout:5000,
    items:1,
  });

  //открыть меню --начало--
    $('#menubutton').click(function(){
      $('div#menu').css({"right":"0"})
      $('div#shadow').css({"z-index":"998", "opacity":"1"})
    });
  //открыть меню --конец--

  //закрыть меню --начало--
    $('#menuclose').click(function(){
      var client_w=document.body.clientWidth;
      $('div#menu').css({"right":"-250px"})
      $('div#shadow').css({"z-index":"-1", "opacity":"0"})
    });

    $('div#shadow').click(function(){
      var client_w=document.body.clientWidth;
      $('div#menu').css({"right":"-250px"})
      $('div#shadow').css({"z-index":"-1", "opacity":"0"})
    });
  //закрыть меню --конец--


  //открыть модалку --начало--
    $('.pr-order').click(function(){
      $('div#shadow').css({"z-index":"999998", "opacity":"1"});
      $('form#form3').css({"z-index":"999999", "opacity":"1"});
    });

    $('#callback').click(function(){
      $('div#shadow').css({"z-index":"999998", "opacity":"1"});
      $('form#form4').css({"z-index":"999999", "opacity":"1"});
    });
    //открыть модалку --конец--

    //закрыть модалку --начало--
    $('div#shadow').click(function(){
      $('div#shadow').css({"z-index":"-1", "opacity":"0"});
      $('form#form3').css({"z-index":"-1", "opacity":"0"});
    });

    $('img#closeform1').click(function(){
      $('div#shadow').css({"z-index":"-1", "opacity":"0"});
      $('form#form3').css({"z-index":"-1", "opacity":"0"});
    });

    $('img#closeform2').click(function(){
      $('div#shadow').css({"z-index":"-1", "opacity":"0"});
      $('form#form4').css({"z-index":"-1", "opacity":"0"});
    });
    //закрыть модалку --конец--

          var bittersMap = (function () {
          var myLatlng = new google.maps.LatLng(51.164840, 71.442296),
          mapCenter = new google.maps.LatLng(51.164840, 71.442296),
          mapCanvas = document.getElementById('map_canvas'),
          mapOptions = {
          center: mapCenter,
          zoom: 13,
          scrollwheel: true,
          draggable: true,
          disableDefaultUI: true,
          mapTypeId: google.maps.MapTypeId.ROADMAP
          },
          map = new google.maps.Map(mapCanvas, mapOptions),
          contentString =
          '<div id="content">'+
          '<div id="siteNotice">'+
          '</div>'+
          '<h1 id="firstHeading" class="firstHeading">Web Aqua</h1>'+
          '<div id="bodyContent"'+
          '<p>ул. Иманова 19, офис 1107с</p>'+
          '</div>'+
          '</div>',
          infowindow = new google.maps.InfoWindow({
          content: contentString,
          maxWidth: 300
          }),
          marker = new google.maps.Marker({
          position: myLatlng,
          map: map,
          title: 'thoughtbot (Sweden)'
          });
          return {
          init: function () {
          map.set('styles', [{
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
          { hue: '#ffff00' },
          { saturation: 30 },
          { lightness: 10}
          ]}
          ]);
          google.maps.event.addListener(marker, 'click', function () {
          infowindow.open(map,marker);
          });
          }
          };
          }());
          bittersMap.init();
})
